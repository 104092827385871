import React, { useState, useEffect } from 'react';
import { Button, FormControlLabel, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { getUserData, changePassword } from '../../api/Api';
import CircularProgress from '@mui/material/CircularProgress';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography'

import Particles from "react-particles";
import { loadConfettiPreset } from "tsparticles-preset-confetti";


const modalStyle = {
    position: 'absolute' as 'absolute',
    // top: '50%',
    // left: '60%',
    // transform: 'translate(-70%, -50%)',
    // border: '1px solid #000',
    // backgroundColor: "rgba(0, 0, 0, 0.7)",
    height: "300px"
};

interface ModalCompleteProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<any>>
}

const QUOTES = [
    "Congratulations on conquering the challenge! Your determination and resilience have paved the way for success.",
    "In the face of adversity, you stood tall and emerged victorious. Cheers to your triumphant conquest of the challenge!",
    "Hats off to you for tackling the challenge head-on and emerging as a true champion. Your success is truly well-deserved!",
    "A challenge met with courage, dedication, and a dash of brilliance – congratulations on turning obstacles into stepping stones!",
    "Your ability to navigate and overcome challenges is truly admirable. Congratulations on mastering the art of triumph!",
    "Bravo! You've not only faced the challenge but also conquered it with flying colors. Your achievement is an inspiration to us all.",
    "Kudos on your remarkable accomplishment! You've proven that challenges are merely opportunities in disguise.",
    "Congratulations on unlocking the door to success by overcoming the challenge before you. Your victory is well-earned and celebrated!",
    "A standing ovation for your outstanding achievement! Your perseverance in the face of challenges is truly commendable.",
    "To conquer a challenge is no small feat, and you've done it with grace and tenacity. Congratulations on your well-deserved success!",
];

const ModalComplete = (props: ModalCompleteProps) => {
    const open = props.open;
    const setOpen = props.setOpen;

    const customInit = async (engine: any) => {
      await loadConfettiPreset(engine);
    };

    return (
        <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={modalStyle}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
       <>
        <Particles options={{
          preset: "confetti",
          background: {
            opacity: 0
          },
        }} init={customInit} />

        <Box style={{
            padding: "0px",
            top: "20%",
            width: "40%",
            marginLeft: "30%",
            marginRight: "auto",
            backgroundColor: "#000",
            position: "fixed"
        }}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h1"
            style={{
                borderBottom: "solid 1px rgba(255, 255, 255, .09)",
                padding: "20px",
                textAlign: "center"
            }}>
            Congratulations!
          </Typography>
          <Typography id="modal-modal-description" sx={{ padding: "20px"}}>
                <h3>{QUOTES[(Math.random() * QUOTES.length) | 0]}</h3>
          </Typography>
        </Box>
        </>
      </Modal>
    );
}

export default ModalComplete;